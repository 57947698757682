import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import useColores from "../hooks/useColores";
import {
    updateBoatFilters,
    updateHouseFilters,
} from "../redux/actions/filtersActions";

const BurguerMenuHome = ({
    burguerMenu,
    setBurguerMenu,
    tipoMoneda,
    cambioDeMoneda,
    logoutUser,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { primario, secundario, setColores } = useColores();
    const authUser = useSelector((state) => state.authReducer);

    return (
        <div
            className={`${
                burguerMenu ? "translate-x-100" : "translate-x-full"
            } fixed top-0 right-0 lg1170:hidden cursor-pointer z-[999] transition-all ease-in-out duration-700 border rounded-lg bg-white overflow-hidden w-full xs:w-1/2`}>
            <div className="relative z-50 flex items-center w-full px-4 overflow-y-auto bg-white rounded-lg">
                <div className={`block bg-white pb-10 h-screen w-full`}>
                    <div
                        className="absolute font-medium border-b right-3 top-1 border-b-black"
                        onClick={() => setBurguerMenu(false)}>
                        Cerrar
                    </div>
                    <p
                        className="pl-2 mt-10 mb-2 font-bold"
                        onClick={() => {
                            navigate("/alquiler-de-alojamientos");
                            dispatch(
                                updateHouseFilters({
                                    fecha_inicio: "",
                                    fecha_final: "",
                                    query_value: "",
                                    query_tipo: "",
                                    numero_personas: 0,
                                    numero_habitaciones: 0,
                                    numero_camas: 0,
                                    numero_banos: 0,
                                    precio_rango: 0,
                                    tipo_moneda: "",
                                    atributos: [],
                                    reglas: [],
                                    ubicacion: [],
                                    tipo_alojamiento: 0,
                                    pagina: 1,
                                    order: "precio",
                                    latitud: 0,
                                    longitud: 0,
                                    zoom: 10,
                                    numero_noches: 0,
                                })
                            );
                        }}>
                        Alojamientos
                    </p>

                    <hr
                        className={` mx-auto border-[${primario}] my-2 pl-2`}></hr>
                    <p
                        className="pl-2 mt-4 mb-2 font-bold"
                        onClick={() => {
                            navigate("/alquiler-de-embarcaciones");
                            dispatch(
                                updateBoatFilters({
                                    fecha: "",
                                    query_value: "",
                                    query_tipo: "",
                                    numero_personas: 0,
                                    tipo_embarcacion: 0,
                                    duracion: [],
                                    pagina: 1,
                                })
                            );
                        }}>
                        Embarcaciones
                    </p>
                    <hr
                        className={` mx-auto border-[${primario}] my-2 pl-2`}></hr>

                    <div
                        onClick={() => {
                            navigate("/blue-sale");
                        }}
                        className={`w-full h-11 cursor-pointer rounded-lg bg-blue-bluecorner text-white hover:bg-blue-bluecorner/90 flex items-center justify-start pl-2`}>
                        <span>Blue Sale</span>
                    </div>

                    {/* <div
                        onClick={() => {
                            navigate("/promo-semana-santa");
                        }}
                        className={`w-full mt-1 h-11 cursor-pointer rounded-lg bg-blue-bluecorner text-white hover:bg-blue-bluecorner/90 flex items-center justify-start pl-2`}>
                        <span>Semana santa</span>
                    </div> */}

                    <hr
                        className={` mx-auto border-[${primario}] my-2 pl-2`}></hr>

                    <div
                        onClick={() => navigate("/aliados")}
                        className={`w-full h-11 cursor-pointer rounded-lg hover:bg-[#E1E1E1]/60  flex items-center justify-start pl-2`}>
                        <p>Conviertete en aliado</p>
                    </div>
                    <hr
                        className={` mx-auto border-[${primario}] my-2 pl-2`}></hr>
                    <p className="pl-2 mt-4 mb-2 font-bold">Moneda</p>

                    <div
                        onClick={() =>
                            tipoMoneda === "USD"
                                ? setBurguerMenu(false)
                                : cambioDeMoneda("USD")
                        }
                        className={` w-full h-11 cursor-pointer rounded-lg hover:bg-[#E1E1E1]/60  flex items-center justify-start pl-2 ${
                            tipoMoneda === "USD" ? `bg-[#E1E1E1]/60 ` : ""
                        } `}>
                        <p>USD</p>
                    </div>
                    <div
                        onClick={() =>
                            tipoMoneda === "PEN"
                                ? setBurguerMenu(false)
                                : cambioDeMoneda("PEN")
                        }
                        className={` w-full h-11 cursor-pointer rounded-lg hover:bg-[#E1E1E1]/60  flex items-center justify-start pl-2 ${
                            tipoMoneda === "PEN" ? `bg-[#E1E1E1]/60 ` : ""
                        } `}>
                        <p>PEN</p>
                    </div>

                    <hr
                        className={` mx-auto border-[${primario}] my-2 pl-2`}></hr>
                    <p className="pl-2 mb-2 font-bold">Cuenta</p>
                    {!authUser.logged ? (
                        <div
                            onClick={() => navigate("/my-account")}
                            className={`w-full h-11 cursor-pointer rounded-lg hover:bg-[#E1E1E1]/60  flex items-center justify-start pl-2`}>
                            <p>Iniciar sesión</p>
                        </div>
                    ) : (
                        <>
                            <div
                                onClick={() => {
                                    authUser.tipo_usuario !== null &&
                                    authUser.tipo_usuario <= 2
                                        ? navigate("/dashboard/datos")
                                        : authUser.tipo_usuario < 6
                                        ? navigate("/perfil/datos")
                                        : navigate(
                                              "/dashboard-empresas/perfil"
                                          );
                                }}
                                className={`w-full h-11 cursor-pointer rounded-lg hover:bg-[#E1E1E1]/60  flex items-center justify-start pl-2`}>
                                <p>Mi perfil</p>
                            </div>
                            <div
                                onClick={() => {
                                    authUser.tipo_usuario < 6
                                        ? navigate("/perfil/reservaciones")
                                        : navigate(
                                              "/dashboard-empresas/reservaciones"
                                          );
                                }}
                                className={
                                    authUser.tipo_usuario > 2
                                        ? `w-full h-11 cursor-pointer rounded-lg hover:bg-[#E1E1E1]/60  flex items-center justify-start pl-2`
                                        : "hidden"
                                }>
                                <p>Mis reservaciones</p>
                            </div>

                            <div
                                onClick={() => navigate("/favoritos")}
                                className={`w-full h-11 cursor-pointer rounded-lg hover:bg-[#E1E1E1]/60  flex items-center justify-start pl-2`}>
                                <p>Favoritos</p>
                            </div>
                            <hr
                                className={` mx-auto border-[${primario}] my-2 pl-2`}></hr>
                            <div
                                onClick={() => (
                                    dispatch(logoutUser()), navigate("/")
                                )}
                                className={`w-full h-11 cursor-pointer rounded-lg hover:bg-[#E1E1E1]/60  flex items-center justify-start pl-2`}>
                                <p>Cerrar sesión</p>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BurguerMenuHome;

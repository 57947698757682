import React from "react";
import { useSelector } from "react-redux";
import BackgroundImage from "../../../img/bluecorner_agradecimiento.png";
import BLUECORNER_PNG from "../../../img/Home/BLUECORNER_PNG.png";

const ExplorerAnoNuevo = () => {
    const authUser = useSelector((state) => state.authReducer);

    return (
        <div
            className={`${
                authUser.tipo_usuario !== null && authUser.tipo_usuario <= 2
                    ? "mt-[8rem]"
                    : "mt-[6rem]"
            }`}>
            <div
                style={{
                    backgroundImage: `url(${BackgroundImage})`,
                    filter: "brightness( 100% ) contrast( 113% ) saturate( 80% ) blur( 0px ) hue-rotate( 0deg )",
                }}
                className={`w-full bg-[#053465]  bg-center bg-no-repeat
                bg-cover flex flex-col text-center`}>
                <div className="h-full bg-[rgba(5,52,101,0.20)] text-white">
                    <h1 className="max-w-md mt-12 py-5 sm:py-14 md:py-24 text-3xl font-bold md:text-4xl md:text-left md:mx-28">
                        Celebra tu año como mereces
                    </h1>
                    <div className="py-2 mt-20 bg-blue-bluecorner text-lg md:text-2xl px-11 md:px-28">
                        <span className="flex flex-col max-w-lg text-left">
                            Encuentra casas desde mínimo 3 noches de alojamiento
                            {/* Pasa unos días de diversión cerca a la naturaleza
                            junto a los que más quieres en una de nuestras
                            casas.
                            <span className="mt-2 text-xs md:text-lg">
                                En Año Nuevo el alquiler minimo es de 3 noches
                            </span> */}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExplorerAnoNuevo;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import priceToMonedaElegida from "../../helpers/priceToMonedaElegida";
import Spinner from "../../Spinner";
import { ShowStarsRating } from "../../StarsRating";
import colores from "../../helpers/colores";
import { normalizeString } from "../../helpers/normalizeString";
import { useSelector } from "react-redux";
const AllCards = () => {
    const HOUSES_ID = [
        15925, 37936, 26169, 34080, 19124, 22782, 33451, 35128, 35155, 35159,
        23185, 19031, 18959,
    ];
    const HOUSES_PRIORITY_ID = [19130];

    const authUser = useSelector((state) => state.authReducer);
    const moneda_elegida = localStorage.getItem("moneda");
    const [houses, setHouses] = useState([]);
    const [indexImagen, setIndexImagen] = useState({
        indexIma: -2,
        indexCar: -1,
    });

    const getHouses = async () => {
        let orden = [];
        const prioridad = await axios.post(
            `${process.env.REACT_APP_PROXY}/casa/getCarrusel/`,
            {
                casas: HOUSES_PRIORITY_ID,
                orden: "ano_nuevo",
            }
        );
        orden.push(...prioridad.data);

        const { data } = await axios.post(
            `${process.env.REACT_APP_PROXY}/casa/getCarrusel/`,
            {
                casas: HOUSES_ID,
                orden: "ano_nuevo",
            }
        );

        orden.push(...data);

        setHouses(orden);
    };

    useEffect(() => {
        getHouses();
    }, []);

    return (
        <div>
            {houses.length > 0 ? (
                <div className="w-full grid-cols-2 gap-4 p-4 mt-8 md:grid lg:grid-cols-3 2xl:grid-cols-4 xl:px-32 2xl:px-28">
                    {houses.map((product, i) => (
                        <div
                            id={product.id}
                            key={product.id}
                            className="relative mt-5 shadow-xl select-none rounded-xl md:mt-0"
                        >
                            <div className="relative w-full  h-[250px] flex">
                                {authUser.logged &&
                                    authUser?.tipo_usuario < 3 && (
                                        <span
                                            // onClick={() =>
                                            //     eliminarImagene(image, index)
                                            // }
                                            className={`absolute right-2 top-2 text-gray-800 dark:text-gray-400 rounded-sm bg-white hover:bg-slate-200 z-20`}
                                            aria-label="close"
                                        >
                                            <svg
                                                className="w-4 h-4 text-blue-500 cursor-pointer"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M6 18L18 6M6 6l12 12"
                                                />
                                            </svg>
                                        </span>
                                    )}
                                <Swiper
                                    modules={[Navigation]}
                                    slidesPerView={1}
                                    navigation
                                    pagination={{
                                        clickable: true,
                                    }}
                                    rewind={true}
                                    loop={true}
                                    longSwipesMs={1200}
                                    // onSwiper={(swiper) =>
                                    //     console.log(swiper)
                                    // }
                                    onSlideNextTransitionStart={() =>
                                        setIndexImagen({
                                            indexIma:
                                                indexImagen.indexCar === i &&
                                                indexImagen.indexIma ===
                                                    product.imagenes.length - 1
                                                    ? -1
                                                    : indexImagen.indexCar === i
                                                    ? indexImagen.indexIma + 1
                                                    : 0,
                                            indexCar: i,
                                        })
                                    }
                                    onSlidePrevTransitionStart={() =>
                                        setIndexImagen({
                                            indexIma:
                                                indexImagen.indexIma > -1 &&
                                                indexImagen.indexCar === i
                                                    ? indexImagen.indexIma - 1
                                                    : product.imagenes.length -
                                                      1,
                                            indexCar: i,
                                        })
                                    }
                                    // onSlideChange={(e) =>
                                    //     console.log(e)
                                    // }
                                >
                                    <SwiperSlide
                                        className="cursor-pointer"
                                        onClick={() =>
                                            window.open(
                                                `/alojamientos/${
                                                    product.id
                                                }/${normalizeString(
                                                    product.nombre
                                                )}`,
                                                "_blank"
                                            )
                                        }
                                    >
                                        <div className="h-full ">
                                            {indexImagen.indexIma > -1 &&
                                            indexImagen.indexCar === i ? (
                                                <LazyLoadImage
                                                    className="object-cover w-full h-full rounded-xl "
                                                    alt="Imagen del producto"
                                                    src={`${
                                                        process.env
                                                            .REACT_APP_PROXY_ARCHIVOS
                                                    }/bluecorner/v1/productoImagenes/getImagen/${
                                                        product.imagenes[
                                                            indexImagen.indexIma
                                                        ]?.imagen_minificada
                                                    }`}
                                                />
                                            ) : (
                                                <LazyLoadImage
                                                    className="object-cover w-full h-full rounded-xl "
                                                    alt="Imagen del producto"
                                                    src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/bluecorner/v1/casa/getImagenPrincipal/${product.imagen_principal_minificada}`}
                                                />
                                            )}
                                        </div>
                                    </SwiperSlide>
                                </Swiper>

                                <div className="absolute bottom-0 left-0 z-10 flex items-center w-full h-20 p-5 text-lg font-bold text-white bg-black bg-opacity-50 2xl:text-2xl">
                                    <span>
                                        {`Desde ${priceToMonedaElegida(
                                            Number(product.precio_ano_nuevo),
                                            product.moneda_precio_base
                                        )} ${
                                            // product.moneda_precio_base
                                            moneda_elegida
                                        }`}
                                    </span>
                                    <span className="mx-2 text-base font-medium">
                                        por{" "}
                                        {product.tipo_estadia === "noche"
                                            ? "noche"
                                            : "día"}
                                    </span>
                                </div>
                            </div>
                            <div className="px-4 py-2">
                                <div className="flex items-center justify-between">
                                    <ShowStarsRating
                                        starsQuantity={5}
                                        width={6}
                                    />
                                    <div className="p-2 mt-1 text-white bg-orange-500 rounded-lg">
                                        Mínimo{" "}
                                        {product.minima_estadia_ano_nuevo}{" "}
                                        {product.tipo_estadia === "noche"
                                            ? "noches"
                                            : "días"}
                                    </div>
                                </div>
                                <div
                                    className={`mt-3 2xl:text-2xl text-lg  text-[${colores.primario}] font-bold  flex justify-between items-center`}
                                >
                                    <a
                                        href={`/alojamientos/${
                                            product.id
                                        }/${normalizeString(product.nombre)}`}
                                        className="cursor-pointer"
                                    >
                                        {product.nombre}
                                    </a>
                                </div>

                                <div className="mt-1 text-base text-gray-500 mb-7">
                                    {product.ciudad}, {product.pais}
                                </div>
                                <div className="absolute bottom-1 left-4 text-amber-600">
                                    <span className="mr-2">
                                        {product.numero_personas} personas
                                    </span>
                                    <span className="mx-2">
                                        {product.numero_habitaciones}{" "}
                                        habitaciones
                                    </span>
                                    <span className="mx-2">
                                        {product.numero_banos} baños
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="justify-center w-full mt-10">
                    <Spinner />
                </div>
            )}
        </div>
    );
};

export default AllCards;
